'use client'
import { XImage } from '@/views/common/XImage'
import { useLocale, useTranslations } from 'next-intl'
import Slider from 'react-slick'
import { StarFilled } from '@ant-design/icons'
import Bg from '@/views/home/bg'
import useMobile from '@/hooks/useMobile'
import TableInfoPc from '@/views/home/TableInfoPc'
import TableInfoMobile from '@/views/home/TableInfoMobile'
import { useRouter } from '@/navigation'

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
}

const bannerImgs = [
  {
    icon: '/images/home/banner/2048.png',
    path: '/play/MEME-Merge2048',
    bg: 'bg-[#FE7808]',
  },
  {
    icon: '/images/home/banner/merge-melon.png',
    path: '/play/MEME-Melon',
    bg: 'bg-[#FBE9A6]',
  },
  {
    icon: '/images/home/banner/gold-miner.png',
    path: '/play/MEME-Miner',
    bg: 'bg-[#8DD953]',
  },
  {
    icon: '/images/home/banner/match-pop.png',
    path: '/play/MatchPop',
    bg: 'bg-[#69E3FB]',
  },
  {
    icon: '/images/home/banner/fla.png',
    path: '/play/FlappyCrypto',
    bg: 'bg-[#FEDE08]',
  },
  {
    icon: '/images/home/banner/SWEE.png',
    path: '/play/MEME-MineSweeper',
    bg: 'bg-[#FC0200]',
  },
  {
    icon: '/images/home/banner/doge-jump.png',
    path: '/play/DogeJumpChallenge',
    bg: 'bg-[#49FE08]',
  },
]
export default function Home() {
  const t = useTranslations('home')
  const locale = useLocale()
  const router = useRouter()
  const nftList = [
    { img: '/images/nft/pepe/smemenft/icon1.png', text: t('A legendary frog figure gets MEME in the world of YOME') },
    { img: '/images/nft/pepe/smemenft/icon2.png', text: t('The initial DOGE bravely enters the world of YOME') },
    { img: '/images/nft/pepe/smemenft/icon3.png', text: t('I will eat MEME legendary mission step by step') },
    { img: '/images/nft/pepe/smemenft/icon4.png', text: t('I am a rising star who will take over the MEME world') },
  ]
  return (
    <div>
      <Bg />
      <main className="min-h-screen px-[12px] lg:px-24 pt-[102px] lg:pt-[140px] pb-24 relative z-1">
        <div className="flex justify-center">
          <div className="text-[16px] lg:text-[30px]  text-center text-[#fff] uppercase font-luck">
            {t('Web3 Meme Gaming Platform')}
          </div>
        </div>
        <div className="mt-[10px] lg:mt-[40px] ">
          <Slider {...{ ...settings, slidesToShow: 2 }}>
            {bannerImgs.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    //@ts-ignore
                    router.replace({ pathname: item.path })
                  }}
                >
                  <div className="relative cursor-pointer pr-[4px] box-content">
                    <XImage src={item.icon} className="w-[100%] rounded-[20px]" />
                    <div className={`absolute z-[-1] ${item.bg} rounded-[20px] top-[4px] left-[2px] w-full h-full`} />
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
        <div className="mt-[40px] flex justify-center">
          <XImage src="/images/home/gamefiicon.png" className="mt-[40px] w-[80px] lg:w-[auto]" />
        </div>
        <div className="text-[16px] lg:text-[30px] mt-[12px] text-center text-[#fff] uppercase  font-luck">
          {t('GameFi focusing on the MEME track')}
        </div>
        <div className="mt-[48px] lg:mt-[56px] grid lg:flex gap-x-[11px] gap-y-[49px] lg:gap-x-[25px] grid-cols-2">
          {nftList.map((item, index) => {
            return (
              <div
                key={index}
                className="w-[100%] lg:w-[311px] h-[206px]  lg:h-[280px] bg-[#1E1B3B] rounded-[20px] relative"
              >
                <div className="w-full flex justify-center">
                  <XImage src={item.img} className="w-[61%] -translate-y-[33%] lg:-translate-y-[15%]" />
                </div>
                <div className="px-[14%] text-[12px] lg:text-[16px] text-center text-[#808C9C] mt-[-20px] lg:mt-0">
                  {item.text}
                </div>
              </div>
            )
          })}
        </div>
        <div className="text-[16px] lg:text-[30px] mt-[80px] text-center text-[#fff] uppercase  font-luck">
          {t('YOME RULES THE MEME WORLD')}
        </div>
        <div className="mt-[8px] lg:mt-[60px] flex flex-col lg:flex-row items-center gap-x-[60px]">
          <div className="text-[#808C9C] text-[14px] lg:text-[24px]">{t('Platform Des')}</div>
          <XImage src="/images/home/panda.png" className="w-[300px] lg:w-[461px] mt-[15px] lg:mt-0" />
        </div>
        <div className="text-[16px] lg:text-[30px] mt-[98px] text-center text-[#fff] uppercase  font-luck">
          {t('RULES FOR EARNING MEME MEDALS')}
        </div>
        <div className="text-[14px] lg:text-[24px] mt-[8px] lg:mt-[38px] text-center text-[#808C9C]">
          {t('Get MEME medal NFT to get $YOME airdrop bonus')}
        </div>
        <div className="block lg:hidden">
          <TableInfoMobile />
        </div>
        <div className="hidden lg:block">
          <TableInfoPc />
        </div>
        <div className="text-[16px] lg:text-[30px] mt-[106px] text-center text-[#fff] uppercase  font-luck">
          {t('Partners')}
        </div>
        {/* <div className="mt-[62px] overflow-hidden">
        <Slider {...settings}>
          <XImage src="/images/home/binance.svg" className="h-[43px]" />
          <XImage src="/images/home/okx.svg" className="h-[43px]" />
          <XImage src="/images/home/gateio.svg" className="h-[43px]" />
          <XImage src="/images/home/solola.svg" className="h-[43px]" />
        </Slider>
      </div> */}
        <div className="mt-[15px] lg:mt-[62px] grid lg:flex gap-x-[5px] lg:gap-x-[70px] justify-center grid-cols-2 gap-y-[20px]">
          <div className="flex justify-center items-center">
            <XImage src="/images/home/fbg.svg" className="h-[25px] lg:h-[43px]" />
          </div>
          <div className="flex justify-center items-center">
            <XImage src="/images/home/lem.svg" className="h-[28px] lg:h-[43px]" />
          </div>
          <div className="flex justify-center items-center">
            <XImage src="/images/home/combo.svg" className="h-[22px] lg:h-[28px]" />
          </div>
          <div className="flex justify-center items-center">
            <XImage src="/images/home/tonp.png" className="h-[27px] lg:h-[43px]" />
          </div>
        </div>
        <div className="text-[12px] text-[#808C9C] text-center mt-[81px] hidden lg:block">
          Copyright ◎ 2024 YOME Finance. All rights reserved
        </div>
      </main>
    </div>
  )
}
