'use client'
import { useRequest } from 'ahooks'
import { isTMA } from '@telegram-apps/sdk'
import PC from '@/views/home/pc'
import Mobile from '@/views/home/mobile'
import { personalCenterService, TInviteInfo } from '@/utils/http/services/personal-center'
import { useUserContext } from '@/context/UserContext'
import InviteModel from '@/views/InviteModal'
import { useModal } from '@/context/ModalContext'
import RedPkg from '@/views/redPkg'
import { judgeIsInRedPkgRainTime } from '@/utils'
import { storage } from '@/utils/storage'
import { useState } from 'react'
import { redPkgRainService, TCheckCodeRes } from '@/utils/http/services/redPkgRain'

export default function Home() {
  const { isLogin } = useUserContext()
  const { openModal } = useModal()
  const [isShowRedPkg, setIsShowRedPkg] = useState(false)

  const { data } = useRequest(isTMA)

  const [invitedInfo, setInvitedInfo] = useState<TInviteInfo>()

  const [checkedCodeData, setCheckedCodeData] = useState<TCheckCodeRes>()
  const {} = useRequest(personalCenterService.getInvitedInfo, {
    ready: isLogin && !!data,
    refreshDeps: [isLogin],
    onSuccess: async (invitedInfo) => {
      setInvitedInfo(invitedInfo)
      const inviteList = invitedInfo?.invited_users || []
      const redPkgStatus = await redPkgRainService.checkRedPkgCode({})
      setCheckedCodeData(redPkgStatus)
      // const nowTime = new Date().getTime()
      // const isInRedPkgRainTime = redPkgStatus.start_time * 1000 < nowTime && redPkgStatus.end_time * 1000 > nowTime
      // if (isHadOpenedRedPkgRain) {
      //   setIsShowRedPkg(false)
      //   if (sessionStorage.getItem('hadOpenedInvite')) return
      //   if (inviteList.length >= 4) return
      //   sessionStorage.setItem('hadOpenedInvite', 'true')
      //   openModal(InviteModel, {})
      // } else {
      //   if (isInRedPkgRainTime) {
      //     setIsShowRedPkg(true)
      //   } else {
      //     setIsShowRedPkg(false)
      //     if (sessionStorage.getItem('hadOpenedInvite')) return
      //     if (inviteList.length >= 4) return
      //     sessionStorage.setItem('hadOpenedInvite', 'true')
      //     openModal(InviteModel, {})
      //   }
      // }

      // if (!!redPkgStatus.id && isInRedPkgRainTime) {
      //   setIsShowRedPkg(true)
      // } else {
      //   setIsShowRedPkg(false)
      //   if (sessionStorage.getItem('hadOpenedInvite')) return
      //   if (inviteList.length >= 4) return
      //   sessionStorage.setItem('hadOpenedInvite', 'true')
      //   openModal(InviteModel, {})
      // }

      if (!!redPkgStatus?.history_id) {
        // isTgNewUser
        if (sessionStorage.getItem('isTgNewUser')) return
        console.log('111', 111)
        setIsShowRedPkg(true)
      } else {
        setIsShowRedPkg(false)
        if (sessionStorage.getItem('hadOpenedInvite')) return
        if (inviteList.length >= 4) return
        sessionStorage.setItem('hadOpenedInvite', 'true')
        openModal(InviteModel, {})
      }
    },
  })
  const onCloseRedPkg = () => {
    setIsShowRedPkg(false)
  }
  return (
    <div>
      {isShowRedPkg && (
        <RedPkg invitedInfo={invitedInfo} data={checkedCodeData} isShow={isShowRedPkg} onClose={onCloseRedPkg} />
      )}

      <div className="hidden lg:block">
        <PC />
      </div>
      <div className="block lg:hidden">
        <Mobile />
      </div>
    </div>
  )
}
