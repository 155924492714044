'use client'
import { XImage } from '@/views/common/XImage'
import { useLocale, useTranslations } from 'next-intl'
import { useRouter } from '@/navigation'

const bannerImgs = [
  {
    icon: '/images/home/mobile/banner/MEME-Merge2048.png',
    path: '/play/MEME-Merge2048',
    bg: 'bg-[#FE7808]',
  },
  {
    icon: '/images/home/mobile/banner/MergeMelon.png',
    path: '/play/MEME-Melon',
    bg: 'bg-[#FEDE08]',
  },
  {
    icon: '/images/home/mobile/banner/GoldMiner.png',
    path: '/play/MEME-Miner',
    bg: 'bg-[#FEDE08]',
  },
  {
    icon: '/images/home/mobile/banner/MatchPop.png',
    path: '/play/MatchPop',
    bg: 'bg-[#FEDE08]',
  },
  {
    icon: '/images/home/mobile/banner/FlappyCrypto.png',
    path: '/play/FlappyCrypto',
    bg: 'bg-[#FEDE08]',
  },
  {
    icon: '/images/home/mobile/banner/DogeJumpChallenge.png',
    path: '/play/DogeJumpChallenge',
    bg: 'bg-[#49FE08]',
  },
  {
    icon: '/images/home/mobile/banner/MEME-MineSweeper.png',
    path: '/play/MEME-MineSweeper',
    bg: 'bg-[#FC0200]',
  },
]
export default function Home() {
  const t = useTranslations('home')
  const locale = useLocale()
  const router = useRouter()
  return (
    <div className="px-[12px] lg:px-24 pt-[80px] pb-10 relative z-1 grid grid-cols-1 gap-y-[15px] gap-x-[15px]">
      {bannerImgs.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              //@ts-ignore
              router.replace({ pathname: item.path })
            }}
          >
            <div className="relative cursor-pointer">
              <XImage src={item.icon} className="w-[100%] rounded-[20px]" />
              <div className={`absolute z-[-1] rounded-[20px] top-[6px] left-[6px] w-full h-full`} />
            </div>
          </div>
        )
      })}
    </div>
  )
}
