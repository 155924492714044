import { StarFilled } from '@ant-design/icons'
import { useTranslations } from 'next-intl'
import { XImage } from '../common/XImage'

const TableInfoMobile = () => {
  const t = useTranslations('home')
  return (
    <div className="grid grid-cols-1 gap-y-[16px] mt-[16px]">
      <div className="flex flex-col gap-y-[2px]">
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('MEME Type NFT')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('MEME Shared NFT')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Rarity')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 flex items-center justify-center ">
            {t('Each NFT can be divided into 4 shard NFTs, and 32 shards in total')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Class')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('Shared')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Percentage')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">100%</div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('How to claim')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('By participating in games')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Usage')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('Upgrade MEME NFT to obtain a Normal NFT')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Rating')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            <StarFilled style={{ color: '#C3B06E' }} />
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Guild')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('YOME will enhance collaboration with gaming partners, and NFT holders will receive airdrops')}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-[2px]">
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('MEME Type NFT')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('MEME NFT')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Rarity')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 flex items-center justify-center ">
            <div className="grid grid-cols-4 gap-x-[12px] gap-y-[10px] justify-center">
              <XImage src="/images/nft/pepe/memenft/icon1.svg" className="h-[36px]" />
              <XImage src="/images/nft/pepe/memenft/icon2.svg" className="h-[36px]" />
              <XImage src="/images/nft/pepe/memenft/icon3.svg" className="h-[36px]" />
              <XImage src="/images/nft/pepe/memenft/icon4.svg" className="h-[36px]" />
            </div>
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Class')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('Normal')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Percentage')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">95%</div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('How to claim')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('By participating in games')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Usage')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('Stake to earn points')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center">
            {t('Rating')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 flex items-center justify-center">
            <div className="flex gap-x-[7px]">
              <StarFilled style={{ color: '#C3B06E' }} />
              <StarFilled style={{ color: '#C3B06E' }} />
            </div>
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Guild')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('YOME will enhance collaboration with gaming partners, and NFT holders will receive airdrops')}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-[2px]">
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('MEME Type NFT')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('Super Rare MEME NFT')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Rarity')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 flex items-center justify-center ">
            <div className="grid grid-cols-4 gap-x-[12px] gap-y-[10px] justify-center">
              <XImage src="/images/nft/pepe/smemenft/icon1.png" className="h-[36px]" />
              <XImage src="/images/nft/pepe/smemenft/icon2.png" className="h-[36px]" />
              <XImage src="/images/nft/pepe/smemenft/icon3.png" className="h-[36px]" />
              <XImage src="/images/nft/pepe/smemenft/icon4.png" className="h-[36px]" />
            </div>
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Class')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('S-Tier')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Percentage')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">5%</div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('How to claim')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('By participating in games')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Usage')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('Staking earns 5x points and expects larger, limited airdrops')}
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center">
            {t('Rating')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 flex items-center justify-center">
            <div className="flex gap-x-[9px]">
              <StarFilled style={{ color: '#C3B06E' }} />
              <StarFilled style={{ color: '#C3B06E' }} />
              <StarFilled style={{ color: '#C3B06E' }} />
            </div>
          </div>
        </div>
        <div className="flex gap-x-[2px]">
          <div className="bg-[#A0EED6] text-[#070921] text-[12px] px-[9px] py-[8px] w-[35%] flex items-center justify-center ">
            {t('Guild')}
          </div>
          <div className="bg-[#1E1B3B] text-[#E3E3E3] text-[12px] px-[9px] py-[8px] flex-1 text-center">
            {t('YOME will enhance collaboration with gaming partners, and NFT holders will receive airdrops')}
          </div>
        </div>
      </div>
    </div>
  )
}
export default TableInfoMobile
