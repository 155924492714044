import { StarFilled } from '@ant-design/icons'
import { useTranslations } from 'next-intl'
import { XImage } from '../common/XImage'

const TableInfoPc = () => {
  const t = useTranslations('home')
  return (
    <div className="mt-[38px] w-full flex justify-center">
      <div className="flex gap-x-[2px] text-[12px]  overflow-auto">
        <div className="grid gap-y-[2px] w-[157px]">
          <div className="bg-[#A0EED6] h-[55px] flex items-center justify-center text-[#070921] px-[28px]">
            {t('MEME Type NFT')}
          </div>
          <div className="bg-[#25233F] h-[104px] flex items-center justify-center px-[28px]">
            {t('MEME Shared NFT')}
          </div>
          <div className="bg-[#25233F] h-[120px] 3xl:h-[100px] flex items-center justify-center px-[28px]">
            {t('MEME NFT')}
          </div>
          <div className="bg-[#25233F] h-[130px] 3xl:h-[100px] flex items-center justify-center px-[28px]">
            {t('Super Rare MEME NFT')}
          </div>
        </div>
        <div className="grid w-[230px]  gap-y-[2px]">
          <div className="bg-[#A0EED6] h-[55px] flex items-center justify-center text-[#070921]">{t('Rarity')}</div>
          <div className="bg-[#25233F] h-[104px] flex items-center justify-center px-[18px]">
            {t('Each NFT can be divided into 4 shard NFTs, and 32 shards in total')}
          </div>
          <div className="bg-[#25233F] h-[120px] 3xl:h-[100px] flex items-center justify-center px-[18px]">
            <div className="grid grid-cols-2 3xl:grid-cols-4 gap-x-[16px] gap-y-[10px] justify-center">
              <XImage src="/images/nft/pepe/memenft/icon1.svg" className="h-[36px]" />
              <XImage src="/images/nft/pepe/memenft/icon2.svg" className="h-[36px]" />
              <XImage src="/images/nft/pepe/memenft/icon3.svg" className="h-[36px]" />
              <XImage src="/images/nft/pepe/memenft/icon4.svg" className="h-[36px]" />
            </div>
          </div>
          <div className="bg-[#25233F] h-[130px] 3xl:h-[100px] flex items-center justify-center px-[18px]">
            <div className="grid grid-cols-2 3xl:grid-cols-4 gap-x-[16px] gap-y-[10px] justify-center">
              <XImage src="/images/nft/pepe/smemenft/icon1.svg" className="h-[36px]" />
              <XImage src="/images/nft/pepe/smemenft/icon2.svg" className="h-[36px]" />
              <XImage src="/images/nft/pepe/smemenft/icon3.svg" className="h-[36px]" />
              <XImage src="/images/nft/pepe/smemenft/icon4.svg" className="h-[36px]" />
            </div>
          </div>
        </div>
        <div className="grid w-[128px]  gap-y-[2px]">
          <div className="bg-[#A0EED6] h-[55px] flex items-center justify-center text-[#070921]">{t('Class')}</div>
          <div className="bg-[#25233F] h-[104px] flex items-center justify-center">{t('Shared')}</div>
          <div className="bg-[#25233F] h-[120px] 3xl:h-[100px] flex items-center justify-center">{t('Normal')}</div>
          <div className="bg-[#25233F] h-[130px] 3xl:h-[100px] flex items-center justify-center">{t('S-Tier')}</div>
        </div>
        <div className="grid w-[128px]  gap-y-[2px]">
          <div className="bg-[#A0EED6] h-[55px] flex items-center justify-center text-[#070921]">{t('Percentage')}</div>
          <div className="bg-[#25233F] h-[104px] flex items-center justify-center">100%</div>
          <div className="bg-[#25233F] h-[120px] 3xl:h-[100px] flex items-center justify-center">95%</div>
          <div className="bg-[#25233F] h-[130px] 3xl:h-[100px] flex items-center justify-center">5%</div>
        </div>
        <div className="block w-[134px]  gap-y-[2px]">
          <div className="bg-[#A0EED6] h-[55px] flex items-center justify-center text-[#070921]">
            {t('How to claim')}
          </div>
          <div
            className="bg-[#25233F]  flex items-center justify-center px-[24px]"
            style={{ height: 'calc(100% - 55px)' }}
          >
            {t('By participating in games')}
          </div>
        </div>
        <div className="grid w-[145px]  gap-y-[2px]">
          <div className="bg-[#A0EED6] h-[55px] flex items-center justify-center text-[#070921]">{t('Usage')}</div>
          <div className="bg-[#25233F] h-[104px] flex items-center justify-center px-[10px]">
            {t('Upgrade MEME NFT to obtain a Normal NFT')}
          </div>
          <div className="bg-[#25233F] h-[120px] 3xl:h-[100px] flex items-center justify-center px-[10px]">
            {t('Stake to earn points')}
          </div>
          <div className="bg-[#25233F] h-[130px] 3xl:h-[100px] flex items-center justify-center px-[10px]">
            {t('Staking earns 5x points and expects larger, limited airdrops')}
          </div>
        </div>
        <div className="grid w-[99px]  gap-y-[2px]">
          <div className="bg-[#A0EED6] h-[55px] flex items-center justify-center text-[#070921]">{t('Rating')}</div>
          <div className="bg-[#25233F] h-[104px] flex items-center justify-center">
            <StarFilled style={{ color: '#C3B06E' }} />
          </div>
          <div className="bg-[#25233F] h-[120px] 3xl:h-[100px] flex items-center justify-center">
            <div className="flex gap-x-[7px]">
              <StarFilled style={{ color: '#C3B06E' }} />
              <StarFilled style={{ color: '#C3B06E' }} />
            </div>
          </div>
          <div className="bg-[#25233F] h-[130px] 3xl:h-[100px] flex items-center justify-center">
            <div className="flex gap-x-[9px]">
              <StarFilled style={{ color: '#C3B06E' }} />
              <StarFilled style={{ color: '#C3B06E' }} />
              <StarFilled style={{ color: '#C3B06E' }} />
            </div>
          </div>
        </div>
        <div className="w-[171px]  gap-y-[2px]">
          <div className="bg-[#A0EED6] h-[55px] flex items-center justify-center text-[#070921]">{t('Guild')}</div>
          <div
            className="bg-[#25233F] h-[104px] flex items-center justify-center px-[32px]"
            style={{ height: 'calc(100% - 55px)' }}
          >
            {t('YOME will enhance collaboration with gaming partners, and NFT holders will receive airdrops')}
          </div>
        </div>
      </div>
    </div>
  )
}
export default TableInfoPc
