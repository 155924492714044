import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'
// @ts-ignore
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { getSelectorsByUserAgent } from 'react-device-detect'
import * as TWEEN from '@tweenjs/tween.js'
import useWindowSize from '@/hooks/useWindowResize'
import { XImage } from '../common/XImage'

let renderer: THREE.WebGLRenderer
let camera: THREE.PerspectiveCamera
let scene: THREE.Scene
let controls: any
let points: THREE.Points

const getPoints = () => {
  const vertices = []
  const SIZE = 3000
  const LENGTH = 3000
  const geometry = new THREE.BufferGeometry()
  const material = new THREE.PointsMaterial({
    // color: 0xffffff,
    color: 0xb5eaff,
  })
  for (let i = 0; i < LENGTH; i++) {
    const x = SIZE * (Math.random() - 0.5)
    const y = SIZE * (Math.random() - 0.5)
    const z = SIZE * (Math.random() - 0.5)
    vertices.push(x, y, z)
  }
  geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
  points = new THREE.Points(geometry, material)

  //   return points
}

const Index = () => {
  const size = useWindowSize()
  const renderRef = useRef<any>()

  const initThree = () => {
    const width = document.getElementById('canvas-frame')?.clientWidth as number
    const height = document.getElementById('canvas-frame')?.clientHeight as number
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, logarithmicDepthBuffer: true }) // 反锯齿antialias:true
    renderer.setSize(width, height)
    renderer.shadowMap.enabled = true
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.shadowMap.enabled = true
    renderRef.current?.appendChild(renderer.domElement)
  }
  const initScene = () => {
    scene = new THREE.Scene()
    getPoints()
    scene.add(points)
  }
  const initCamera = () => {
    const width = document.getElementById('canvas-frame')?.clientWidth as number
    const height = document.getElementById('canvas-frame')?.clientHeight as number
    camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000000)
    camera.position.x = 0
    camera.position.y = 0
    camera.position.z = 0
    camera.up.x = 0
    camera.up.y = 1
    camera.up.z = 0
    camera.lookAt(scene.position)
  }
  const initLight = () => {
    // let ambientLight = new THREE.AmbientLight(0xffffff)
    // scene.add(ambientLight)
    const light = new THREE.PointLight(0xffffff, 0.2, 0)
    light.position.set(500, 1500, 500)
    scene.add(light)
  }
  const initControls = () => {
    controls = new OrbitControls(camera, renderer.domElement)
    controls.enabled = false
    controls.enablePan = false
    controls.enableZoom = false
    controls.autoRotate = false
    controls.enableRotate = false
    controls.enableDamping = false
    // const axes = new THREE.AxesHelper(200000)
    // axes.setColors('red', 'yellow', 'blue')
    // scene.add(axes)
  }

  const renderObj = () => {
    requestAnimationFrame(() => {
      TWEEN.update()
      controls.update()
      points.rotation.y += 0.001
      let rot = 0
      const radian = (rot * Math.PI) / 180

      rot += 0.1
      camera.position.x = 1000 * Math.sin(radian)
      camera.position.z = 1000 * Math.cos(radian)
      renderer.render(scene, camera)
      renderObj()
    })

    // renderer.render(scene, camera)
  }

  const reRender = () => {
    const width = document.getElementById('canvas-frame')?.clientWidth as number
    const height = document.getElementById('canvas-frame')?.clientHeight as number
    if (camera) {
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderer.setSize(width, height)
    }
  }
  const init = () => {
    initThree()
    initScene()
    initCamera()
    initLight()
    initControls()
    renderObj()
  }

  useEffect(() => {
    const userAgent = window.navigator.userAgent
    const { isMobile } = getSelectorsByUserAgent(userAgent)
    if (renderRef && renderRef.current && renderRef.current.children.length === 0 && !isMobile) {
      init()
    }
  }, [renderRef])
  useEffect(() => {
    return () => {
      if (renderer && renderer.domElement) {
        let cvs = renderer.domElement
        let cxt = cvs.getContext('webgl')
        cxt?.clear(cxt.DEPTH_BUFFER_BIT)
      }
    }
  }, [])
  useEffect(() => {
    reRender()
  }, [size])
  return (
    <div className="fixed w-full h-[100vh] overflow-hidden">
      <div id="canvas-frame" ref={renderRef} className="w-full h-[100vh] border-none outline-0" />
      <XImage src="/images/home/cloud.svg" className="absolute -left-[200px] top-[72vh] animate-wiggle" />
      <XImage src="/images/home/cloud2.svg" className="absolute right-[284px]  top-[57vh] animate-wiggle" />
    </div>
  )
}

export default Index
