import { ModalComponent } from '@/context/ModalContext'
import { useTranslations } from 'next-intl'
import Modal from './modal/Modal'
import InviteList from './personal-center/task/InviteList'
import { useRequest } from 'ahooks'
import { personalCenterService } from '@/utils/http/services/personal-center'
import R from '@/utils/http/request'
import { XImage } from './common/XImage'
import { inviteTgUrl, shareText, tgChannel } from '@/utils/constants'
import Btn from '@/views/redPkg/Btn'
import { initUtils } from '@telegram-apps/sdk'
import { useUserContext } from '@/context/UserContext'

const InviteModel: ModalComponent<boolean, {}> = (props) => {
  const t = useTranslations('inviteModal')
  const { data: invitedInfo, refresh: refreshInvitedInfo } = useRequest(R(personalCenterService.getInvitedInfo, {}), {})

  const { userInfo: tgUserInfo } = useUserContext()

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      className={
        'w-[95%] lg:w-[409px] bg-transparent h-fit rounded pt-[0] lg:pt-[0] px-[0px] lg:px-[13px] pb-[30px] lg:pb-[50px] relative'
      }
    >
      <div className="relative">
        <div className="relative z-[3] h-[348px] flex justify-center">
          <div className="h-full bg-[#070921] p-[6px] rounded-[32px] w-[95%]">
            <div className="px-[8px] rounded-[32px]  h-full  pt-[34px] border border-solid border-[#16142E] relative">
              <XImage
                src="/images/icon/close_sm.svg"
                className="absolute top-[12px] right-[13px] cursor-pointer"
                onClick={props.onClose}
              />
              <div className="text-[22px] lg:text-[30px] text-[#fff] text-center">{t('Congratulations')}</div>
              <div className="text-[14px] lg:text-[16px] text-[#808C9C] mt-[20px] lg:mt-[33px] text-center">
                {t('You had earned')}
                {'  '}
                <span className="text-[24px] text-[#A0EED6]">
                  {invitedInfo?.invited_points || 0} {t('Points')}
                </span>
              </div>
              <div className="mt-[21px]">
                <InviteList
                  list={invitedInfo?.invited_users || []}
                  inviteCode={tgUserInfo?.code || invitedInfo?.invited_code || ''}
                  inviteRootCode={tgUserInfo?.root_code || invitedInfo?.invited_root_code || ''}
                />
              </div>
              <div className="text-[#E3E3E3] text-[14px] text-center mt-[16px]">
                {t('Invite friends to boost your Points')}
              </div>
            </div>
          </div>
        </div>
        <div
          className="rounded-[32px] h-[192px] absolute z-0 w-full bottom-[-31px] left-0"
          style={{
            background: 'linear-gradient(90deg, #A0EED6 0%, #639586 100%)',
            boxShadow: '0px 1px 3px 0px #FFF inset',
          }}
        />
        <div
          className="rounded-[32px] h-[156px] absolute z-[1] w-full bottom-[-43px] left-0  flex justify-center items-center"
          style={{ backgroundSize: '100% 100%' }}
        />
        <div
          className={`rounded-[32px] h-[156px] absolute z-[3] w-full bottom-[-80px] left-0 bg-[url('/images/invite/modalbottom.svg')] flex justify-center items-center `}
          style={{ backgroundSize: '100% 100%' }}
        >
          <div
            className={`w-[80%] h-[40px] rounded-[20px] relative cursor-pointer mt-[26px]`}
            onClick={() => {
              if (invitedInfo?.invited_code || tgUserInfo?.code) {
                window.open(
                  `${inviteTgUrl}?startapp=${tgUserInfo?.code || invitedInfo?.invited_code || ''}${
                    tgUserInfo?.root_code || invitedInfo?.invited_root_code
                      ? '--' + (tgUserInfo?.root_code || invitedInfo?.invited_root_code)
                      : ''
                  }&text=${encodeURIComponent(shareText)}`,
                )
              }
            }}
          >
            {(invitedInfo?.invited_code || tgUserInfo?.code) && (
              <XImage src="/images/my/invitebgicon.png" className="absolute left-[16px] top-[10px] z-[2] h-[4px]" />
            )}
            <div
              className={`absolute h-full w-full  rounded-[20px] z-[1] left-0 top-0 flex justify-center items-center text-[#070921] text-[16px] ${
                !invitedInfo?.invited_code && !tgUserInfo?.code
                  ? 'text-[#1A1D1F] bg-[#3C3A5B] cursor-not-allowed'
                  : 'bg-[#fce447] cursor-pointer'
              }`}
            >
              {t('Send to Invite')}
            </div>
            <div
              className={`absolute h-full w-full  rounded-[20px] z-0 left-0 top-[4px] ${
                !invitedInfo?.invited_code && !tgUserInfo?.code
                  ? 'text-[#1A1D1F] bg-[#3C3A5B] cursor-not-allowed'
                  : 'bg-[#FFBE10] cursor-pointer'
              }`}
            ></div>
          </div>
        </div>
      </div>
      <div className="rounded-[28px] border border-dashed border-[#A0EED6] py-[11px] px-[24px] flex items-center justify-between mt-[64px]">
        <div>
          <div className="text-[20px] text-[#fff]">{t('YOME Community')}</div>
          <div className="text-[#A0EED6] text-[15px] mt-[0px]">{t('Moer Rewards')}</div>
        </div>
        <Btn
          hiddenIcon={false}
          className="w-[80px]"
          disabled={false}
          loading={false}
          text={t('Join')}
          onClick={() => {
            const utils = initUtils()
            utils.openTelegramLink(tgChannel)
          }}
        />
      </div>
    </Modal>
  )
}
export default InviteModel
