import { inviteTgUrl, shareText } from '@/utils/constants'
import { TInviteInfo } from '@/utils/http/services/personal-center'
import { XImage } from '@/views/common/XImage'
import { useTranslations } from 'next-intl'
import { FC } from 'react'

const InviteList: FC<{ list: TInviteInfo['invited_users']; inviteCode: string; inviteRootCode: string }> = ({
  list,
  inviteCode,
  inviteRootCode,
}) => {
  const t = useTranslations('invite')
  const baseInviteList = [
    { returnPoints: 50 },
    { returnPoints: 80 },
    { returnPoints: 100 },
    { returnPoints: 120 },
    { returnPoints: t('More') },
  ]
  return (
    <div>
      <div className="flex gap-x-[0] lg:gap-x-[15px] justify-between lg:justify-center">
        {baseInviteList.map((item, index) => {
          return (
            <div
              key={index}
              className="bg-[#242141] lg:bg-[#070921] rounded-[20px] py-[12px] px-[14px] lg:px-[21px] flex flex-col items-center"
            >
              {list[index] ? (
                <XImage src={list[index]['avatar']} width={30} />
              ) : (
                <div
                  className="border border-dotted border-[#5E6C7F] w-[28px] h-[28px] rounded-full flex justify-center items-center text-[13px] text-[#5E6C7F] cursor-pointer"
                  onClick={() => {
                    if (inviteCode) {
                      window.open(
                        `${inviteTgUrl}?startapp=${inviteCode}${
                          inviteRootCode ? '--' + inviteRootCode : ''
                        }&text=${encodeURIComponent(shareText)}`,
                      )
                    }
                  }}
                >
                  ?
                </div>
              )}

              <div className="mt-[10px] text-[#808C9C] text-[10px]">
                <div className="text-center">
                  {index !== baseInviteList.length - 1 && '+'}
                  {item.returnPoints}
                </div>
                <div className="text-center capitalize">{t('Points')}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="bg-[#242141] lg:bg-[#070921] h-[2px] rounded-[1px] relative mt-[18px] w-[90%] translate-x-[-50%] ml-[50%]">
        <div
          className="absolute left-0 top-0 h-[2px] rounded-[1px] bg-[#A0EED6] w-[20%] flex justify-between"
          style={{ width: `${(((list.length || 0) > 5 ? 5 : list.length || 0) / 5) * 100}%` }}
        >
          <div className="w-[6px] h-[6px] bg-[#A0EED6] rounded-full mt-[-2px]" />
          <div className="w-[6px] h-[6px] bg-[#A0EED6] rounded-full mt-[-2px]" />
        </div>
      </div>
    </div>
  )
}
export default InviteList
